import React, { ReactNode, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Loader from '../loader/loader.component';

interface Props {
  children: ReactNode;
}

const AuthWrapper: React.FC<Props> = ({ children }) => {
  const { initialized, keycloak } = useKeycloak();

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak.login({ idpHint: 'okta' });
      keycloak.onTokenExpired = (): void => {
        keycloak.updateToken(0);
      };
    }
  }, [initialized]);

  if (!initialized) {
    return <Loader />;
  }

  return <>{keycloak.authenticated ? children : <Loader />}</>;
};

export default AuthWrapper;
