import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Loader: React.FC = () => {
  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <ClipLoader />
    </div>
  );
};

export default Loader;
