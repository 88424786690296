import { User } from '@2sixty/os-web-root-api';
import { KeycloakProfile } from 'keycloak-js';

export const mapToContextUser = ({
  id,
  email,
  emailVerified,
  firstName,
  lastName,
  createdTimestamp,
}: KeycloakProfile): User => {
  const createdAt = createdTimestamp ? new Date(createdTimestamp).toISOString() : '';

  return {
    createdAt,
    updatedAt: createdAt,
    id: id || '',
    email: email || '',
    firstname: firstName || '',
    lastname: lastName || '',
    active: !!emailVerified,
    agency: '',
    country: '',
    sourceId: '',
    sourceType: '',
  };
};
