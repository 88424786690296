import { MicroAppProps, Context, Config, MicroAppAction } from '@2sixty/os-web-root-api';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';
import * as singleSpa from 'single-spa';

import { mapToContextUser } from '../utils';

type ExtendedMicroAppProps = Omit<MicroAppProps, 'baseHref'> & {
  standalone: boolean;
  baseUrl$: BehaviorSubject<string>;
  baseHref?: string;
};

const AppName = APP_NAME || process.env.REACT_APP_APP_NAME;
const AppUrl = APP_SCRIPT_URL || process.env.REACT_APP_APP_SCRIPT_URL;

export const useMicroApp = (containerRef: React.RefObject<HTMLDivElement>): void => {
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (containerRef.current) {
      const props = createProps(containerRef);
      const appConfig = createAppConfig(props);

      if (singleSpa.getMountedApps().includes(AppName)) {
        (async (): Promise<void> => {
          await singleSpa.unregisterApplication(AppName);
          singleSpa.registerApplication(appConfig);
        })();
      } else {
        singleSpa.registerApplication(appConfig);
      }
    }
    return () => {
      if (singleSpa.getMountedApps().includes(AppName)) {
        singleSpa.unregisterApplication(AppName);
      }
    };
  }, [containerRef]);

  const jwt$ = new BehaviorSubject(keycloak.token ?? '');
  const context$ = new BehaviorSubject({} as Context);

  useEffect(() => {
    if (initialized) {
      keycloak.onAuthRefreshSuccess = (): void => {
        jwt$.next(keycloak.token ?? '');
      };
    }
  }, [initialized]);

  useEffect(() => {
    if (!context$.value.user)
      (async (): Promise<void> => {
        const keycloakProfile = await keycloak.loadUserProfile();

        context$.next({ user: mapToContextUser(keycloakProfile) } as Context);
      })();
  }, []);

  const createProps = (container: React.RefObject<HTMLDivElement>): ExtendedMicroAppProps => ({
    baseUrl$: new BehaviorSubject('/'),
    assetsPath: AppUrl.replace('main.js', ''),
    overlayContainer: container.current!,
    config$: new BehaviorSubject({} as Config),
    context$,
    jwt$,
    eventEmitter$: new BehaviorSubject({} as MicroAppAction),
    events$: new BehaviorSubject({} as MicroAppAction),
    standalone: true,
  });

  const insertScriptAndRun = (url: string): Promise<any> =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.onload = resolve;
      script.onerror = reject;

      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode?.insertBefore(script, firstScript);
    });

  const loadApp = async (): Promise<singleSpa.Application<MicroAppProps>> => {
    const app = window.proteusOnboarding;

    if (app) {
      return Promise.resolve(app);
    }

    await insertScriptAndRun(AppUrl);

    return Promise.resolve(window.proteusOnboarding);
  };

  const createAppConfig = (props: ExtendedMicroAppProps): singleSpa.RegisterApplicationConfig<ExtendedMicroAppProps> =>
    ({
      name: AppName,
      app: () => loadApp(),
      activeWhen: () => true,
      customProps: props,
    } as singleSpa.RegisterApplicationConfig<ExtendedMicroAppProps>);
};
