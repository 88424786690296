import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import React, { useEffect, useRef } from 'react';
import * as singleSpa from 'single-spa';

import MicroAppWrapper from './components/micro-app-wrapper/micro-app-wrapper.component';
import AuthWrapper from './components/auth-wrapper/auth-wrapper.component';

const AppId = APP_ID || process.env.REACT_APP_APP_ID;

const keycloak = new Keycloak({
  url: KEYCLOAK_CONFIG_URL || process.env.REACT_APP_KEYCLOAK_CONFIG_URL,
  realm: KEYCLOAK_CONFIG_REALM || process.env.REACT_APP_KEYCLOAK_CONFIG_REALM,
  clientId: KEYCLOAK_CONFIG_CLIENT_ID || process.env.REACT_APP_KEYCLOAK_CONFIG_CLIENT_ID,
});

const App: React.FC = () => {
  const microAppContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    singleSpa.start();
  });
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        checkLoginIframe: false,
      }}
    >
      <AuthWrapper>
        <main>
          <div ref={microAppContainerRef} id={AppId}>
            <MicroAppWrapper containerRef={microAppContainerRef} />
          </div>
        </main>
      </AuthWrapper>
    </ReactKeycloakProvider>
  );
};

export default App;
