import React from 'react';

import { useMicroApp } from '../../hooks/useMicroApp';
import Loader from '../loader/loader.component';

interface Props {
  containerRef: React.RefObject<HTMLDivElement>;
}

const MicroAppWrapper: React.FC<Props> = ({ containerRef }) => {
  useMicroApp(containerRef);

  return <Loader />;
};

export default MicroAppWrapper;
